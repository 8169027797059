import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { staticClass: "dlg-edit-shift-time", attrs: { "ok-text": "Save", "title": "Edit Shift Time", "after-close": _vm.afterModalClose, "width": 522 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v(" Save ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Facility", "source": "code", "source-label": _vm.generateLabel, "data-source": _vm.arrFacility, "value": _vm.editForm.facilityCode, "rules": "required", "disabled": true }, on: { "change": _vm.onFacilityChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Department", "data-source": _vm.filterArea, "source": "id", "source-label": "name", "value": _vm.editForm.areaID, "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("areaID", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { key: "code", attrs: { "form-item": "", "label": "Shift ID", "value": _vm.editForm.code, "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("code", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { key: "shiftDescription", attrs: { "form-item": "", "label": "Description", "value": _vm.editForm.shiftDescription, "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("shiftDescription", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("time-picker", { key: "startTime", attrs: { "form-item": "", "format-time": "HH:mm", "label": "Start Time", "rules": "required", "value": _vm.editForm.startTime }, on: { "change": function($event) {
      return _vm.selectTime("startTime", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("time-picker", { key: "finishTime", attrs: { "form-item": "", "format-time": "HH:mm", "label": "Finish Time", "rules": "required", "value": _vm.editForm.finishTime }, on: { "change": function($event) {
      return _vm.selectTime("finishTime", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "shiftHours", attrs: { "form-item": "", "label": "Shift Hours", "rules": "required", "disabled": "", "value": _vm.editForm.shiftHours } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditProductStandard",
  inject: ["crud"],
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      editForm: {},
      visible: false,
      isLoading: false,
      filterArea: []
    };
  },
  computed: {
    arrFacility() {
      return this.$store.getters["identities.common.facilities/list"] || [];
    }
  },
  watch: {
    "editForm.startTime": function() {
      this.calculateShift();
    },
    "editForm.finishTime": function() {
      this.calculateShift();
    }
  },
  mounted() {
    this.visible = true;
  },
  async created() {
    this.entity = await this.crud.getEntity();
    const res = await this.getListDepartment(this.entity.facilityCode);
    this.filterArea = res.data.length > 0 ? res.data : [];
    Object.entries(this.entity).forEach(([key, value]) => this.storeValue(key, value));
  },
  methods: {
    calculateShift() {
      if (this.editForm.startTime && this.editForm.finishTime) {
        const startTime = this.$moment(this.editForm.startTime, "HH:mm");
        const finishTime = this.$moment(this.editForm.finishTime, "HH:mm");
        if (startTime.isSameOrAfter(finishTime)) {
          startTime.subtract(1, "days");
        }
        const hours = finishTime.diff(startTime, "hours");
        this.storeValue("shiftHours", hours);
      }
    },
    async onFacilityChange(facilityCode) {
      const res = await this.getListDepartment(facilityCode);
      this.filterArea = res.data.length > 0 ? res.data : [];
      this.editForm.areaID = "";
    },
    generateLabel(facility) {
      return facility.description ? `${facility.name} - ${facility.description}` : `${facility.name}`;
    },
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    submit() {
      this.isLoading = true;
      this.crud.submitEntity("replace").then(() => {
        const filter = this.crud.getFilter("areaID");
        if (filter) {
          this.crud.fetchList(false, void 0, true, `areaID=${filter}`, false);
        }
        this.visible = false;
        this.isLoading = false;
      }).catch(() => this.isLoading = false);
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push("/line-performance/shifts");
    },
    selectTime(timeField, value) {
      this.storeValue(timeField, value);
    },
    getListDepartment(facilityCode) {
      return this.axios.get(`${this.apiUrl}/identities/common/departments?facility=${facilityCode}&appCode=line-performance`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditShiftTime = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-shift-time" }, [_c("resource", { attrs: { "name": "identities.common.facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.shifts", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/shifts" } }, [_c("edit-shift-time")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditShiftTime
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
